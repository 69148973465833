<template>
  <div class="scrollable">
    <div class="__contentainer">
      <div class="__loading" v-if="loadingTemplate">
        <div class="div" style="height: 80vh" v-loading="loadingTemplate"></div>
      </div>
      <div id="__messsages" v-if="!loadingTemplate">
        <div class="messages__navigation contains-message-buttons">
          <div class="main-buttons-container">
            <button
              class="__reporting__nav"
              @click="
                $router.replace('/sms/messages').catch((err) => {
                  if (err.name !== 'NavigationDuplicated') throw err;
                })
              ">
              {{ $t("dashboard.send_message.message_templates.template") }}
            </button>
            <button class="is-active">
              {{
                $t(
                  "dashboard.send_message.scheduled_messages.scheduled_message"
                )
              }}
            </button>
            <button
              class="international-button"
              @click="
                $router.push('/sms/international-messages').catch((err) => {
                  if (err.name !== 'NavigationDuplicated') throw err;
                })
              ">
              {{
                $t(
                  "dashboard.send_message.international_messages.international"
                )
              }}
              <!-- <div class="is-new">New!</div> -->
            </button>
          </div>
          <!-- Send Message Button -->
          <button
            class="is-active create-template"
            @click="openMessageModal('sendMessage')"
            v-if="
              Array.isArray(messageTemplates) && messageTemplates.length > 0
            ">
            <img
              class="mr-1"
              src="../../../assets/send__light.svg"
              alt="send icon" />
            {{
              $t("dashboard.send_message.scheduled_messages.schedule_message")
            }}
          </button>
        </div>
        <div class="__main_messages">
          <!-- MESSAGES EMPTY STATE -->

          <!-- MESSAGES EMPTY STATE -->
          <!-- MESSAGES -->
          <div class="__all__messages">
            <div id="__messages__card" class="mnotify-card">
              <div id="side-indicators-content" class="mnotify-card-header">
                <h3 class="mnotify-card-header-text py-0 my-0">Messages</h3>
              </div>
              <div class="mnotify-card-body">
                <div class="__nodata_messages">
                  <div class="__nodata_messages table_wrapper">
                    <el-table
                      v-loading="loadingTable"
                      :header-cell-style="{
                        background: 'rgba(212, 216, 226, 0.1)',
                      }"
                      :data="displayData"
                      ref="singleTable"
                      :height="customHeight"
                      width="90vmax">
                      <template v-slot:empty>
                        <div>
                          <div class="no_message_message">
                            <h3 class="__nodata__header">
                              {{
                                $t(
                                  "dashboard.send_message.scheduled_messages.no_scheduled"
                                )
                              }}
                            </h3>
                            <button
                              class="__create_a_new_template"
                              @click="openMessageModal('template')">
                              {{
                                $t(
                                  "dashboard.send_message.message_templates.create_template"
                                )
                              }}
                            </button>
                            <div class="__button_wrapper">
                              <button @click="openMessageModal('sendMessage')">
                                {{
                                  $t(
                                    "dashboard.send_message.scheduled_messages.create_scheduled"
                                  )
                                }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </template>
                      <el-table-column
                        :label="$t('misc.table.message_title')"
                        prop="campaign_name"
                        :width="customWidth">
                        <template slot-scope="scope">
                          <div class="notranslate">
                            {{ scope.row.campaign_name }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        :label="$t('misc.table.content')"
                        :width="customWidth">
                        <template slot-scope="scope">
                          <div class="notranslate">
                            {{ scope.row.message.substr(0, 19)
                            }}{{ scope.row.message.length > 19 ? "..." : "" }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        :label="
                          $t(
                            'dashboard.send_message.scheduled_messages.table.scheduled'
                          )
                        "
                        :width="customWidth">
                        <template slot-scope="scope">
                          {{
                            moment(scope.row.date_time).format(
                              "ddd D MMM YY h:mma"
                            )
                          }}
                        </template>
                      </el-table-column>
                      <!-- Last Sent -->
                      <el-table-column
                        :label="
                          $t(
                            'dashboard.send_message.scheduled_messages.table.last_sent'
                          )
                        "
                        :width="customWidth">
                        <template slot-scope="scope">
                          <span
                            style="font-size: 12px; height: 100"
                            v-if="
                              scope.row.is_repeated == 1 &&
                              scope.row.status == 4
                            "
                            >{{
                              moment(scope.row.last_date_time).format(
                                "ddd D MMM YY h:mma"
                              )
                            }}</span
                          >
                        </template>
                      </el-table-column>
                      <!-- Receipients Column -->
                      <el-table-column
                        :label="
                          $t(
                            'dashboard.send_message.scheduled_messages.table.recipients'
                          )
                        "
                        :width="customWidth"
                        style="text-align: center">
                        <template slot-scope="scope">
                          <p
                            style="
                              position: relative;
                              left: 30px;
                              margin: 0 auto;
                            ">
                            {{ scope.row.recipeints }}
                          </p>
                        </template>
                      </el-table-column>
                      <!-- Receipients Column End -->
                      <!-- Is Repeating -->
                      <el-table-column
                        :label="
                          $t(
                            'dashboard.send_message.scheduled_messages.table.type'
                          )
                        "
                        :width="customWidth"
                        style="text-align: center">
                        <template slot-scope="scope">
                          <p
                            style="margin: 0 auto; color: #ff8066"
                            v-if="scope.row.is_repeated == 1">
                            REPEATED
                          </p>
                          <p style="margin: 0 auto; color: #000000" v-else>
                            REGULAR
                          </p>
                        </template>
                      </el-table-column>
                      <!-- End -->
                      <!-- Is repeating -->
                      <el-table-column
                        :label="
                          $t(
                            'dashboard.send_message.scheduled_messages.table.repeat_period'
                          )
                        "
                        :width="customWidth"
                        style="text-align: center">
                        <template slot-scope="scope">
                          <p
                            style="margin: 0 auto; color: #ff9800"
                            v-if="scope.row.repeated_period == 'DAILY'">
                            {{
                              $t(
                                "dashboard.send_message.scheduled_messages.table.daily"
                              )
                            }}
                          </p>
                          <p
                            style="margin: 0 auto; color: #ff9800"
                            v-else-if="scope.row.repeated_period == '1_WEEK'">
                            {{
                              $t(
                                "dashboard.send_message.scheduled_messages.table.week_1"
                              )
                            }}
                          </p>
                          <p
                            style="margin: 0 auto; color: #ff9800"
                            v-else-if="scope.row.repeated_period == '2_WEEK'">
                            {{
                              $t(
                                "dashboard.send_message.scheduled_messages.table.week_2"
                              )
                            }}
                          </p>
                          <p
                            style="margin: 0 auto; color: #ff9800"
                            v-else-if="scope.row.repeated_period == '3_WEEK'">
                            {{
                              $t(
                                "dashboard.send_message.scheduled_messages.table.week_3"
                              )
                            }}
                          </p>
                          <p
                            style="margin: 0 auto; color: #ff9800"
                            v-else-if="scope.row.repeated_period == 'MONTHLY'">
                            {{
                              $t(
                                "dashboard.send_message.scheduled_messages.table.monthly"
                              )
                            }}
                          </p>
                          <p style="margin: 0 auto; color: #000000" v-else>-</p>
                        </template>
                      </el-table-column>
                      <!-- Status Column -->
                      <el-table-column label="Status" :width="customWidth">
                        <template slot-scope="scope">
                          <p
                            style="margin: 0 auto; color: #ef9e14"
                            v-if="scope.row.status == 0">
                            {{ $t("misc.status.pending") }}
                          </p>
                          <p
                            style="margin: 0 auto; color: #28a745"
                            v-else-if="scope.row.status == 1">
                            {{ $t("misc.status.completed") }}
                          </p>
                          <p
                            style="margin: 0 auto; color: #00b4d8"
                            v-else-if="scope.row.status == 2">
                            {{ $t("misc.status.in_progress") }}
                          </p>
                          <p
                            style="margin: 0 auto; color: #ff0000"
                            v-else-if="scope.row.status == 3">
                            {{ $t("misc.status.accepted") }}
                          </p>

                          <p
                            style="margin: 0 auto; color: #28a745"
                            v-else-if="scope.row.status == 4">
                            {{ $t("misc.status.repeating") }}
                          </p>
                          <!-- ref="status_content">{{scope.row.status == 1 ? "COMPLETED" :"Ed"}}</p> -->
                        </template>
                      </el-table-column>
                      <!-- Status Column End -->
                      <el-table-column
                        label="Actions"
                        style="border: 1px solid red"
                        :width="customWidth"
                        class="actions-header">
                        <template slot-scope="scope">
                          <div class="actions-icons">
                            <button
                              v-if="scope.row.status !== 1"
                              @click="
                                opendModal({
                                  message: scope.row,
                                  key: scope.$index,
                                  type: 'send',
                                  sendType: '__scheduled',
                                })
                              "
                              class="tb_btn __send"
                              title="Edit Scheduled Message">
                              <!-- <img width="20px" height="20px"
                                                                src="@/assets/Edit.svg" alt="">
                                                             -->
                              <svg
                                width="19"
                                height="18"
                                viewBox="0 0 19 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M6.64691 17.0279L15.4333 5.66544C15.9108 5.0527 16.0806 4.3443 15.9214 3.62299C15.7835 2.96726 15.3802 2.34377 14.7754 1.87078L13.3004 0.699064C12.0164 -0.322161 10.4246 -0.214663 9.51203 0.957057L8.52516 2.23735C8.39782 2.39752 8.42965 2.63401 8.58882 2.76301C8.58882 2.76301 11.0825 4.76246 11.1356 4.80546C11.3054 4.96671 11.4327 5.1817 11.4646 5.43969C11.5176 5.94493 11.1674 6.41792 10.6475 6.48242C10.4034 6.51467 10.1699 6.43942 10.0002 6.29967L7.3791 4.21422C7.25177 4.11855 7.06076 4.13898 6.95464 4.26797L0.725646 12.3303C0.322406 12.8355 0.184456 13.4912 0.322406 14.1255L1.11827 17.5761C1.16072 17.7589 1.31989 17.8879 1.5109 17.8879L5.01272 17.8449C5.64942 17.8341 6.24367 17.5439 6.64691 17.0279ZM11.5502 15.9533H17.2603C17.8174 15.9533 18.2705 16.4123 18.2705 16.9766C18.2705 17.5421 17.8174 18 17.2603 18H11.5502C10.9931 18 10.54 17.5421 10.54 16.9766C10.54 16.4123 10.9931 15.9533 11.5502 15.9533Z"
                                  fill="#D4D8E2" />
                              </svg>
                            </button>
                            <button
                              class="tb_btn _delete"
                              @click="
                                opendModal({
                                  message: scope.row,
                                  key: scope.$index,
                                  type: 'delete',
                                  deleteType: '__scheduled',
                                })
                              "
                              title="Delete Scheduled Message">
                              <!-- <img width="20px" height="20px"
                                                                @click="opendModal({ message: scope.row, key: scope.$index, type: 'delete', deleteType: '__scheduled' })"
                                                                src="@/assets/__delete.svg" alt=""> -->
                              <svg
                                width="19"
                                height="20"
                                viewBox="0 0 19 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M17.4185 3.30531C17.8087 3.30531 18.1335 3.62537 18.1335 4.03263V4.40918C18.1335 4.80653 17.8087 5.1365 17.4185 5.1365H0.796052C0.4049 5.1365 0.0800781 4.80653 0.0800781 4.40918V4.03263C0.0800781 3.62537 0.4049 3.30531 0.796052 3.30531H3.72042C4.31447 3.30531 4.83145 2.88813 4.96509 2.29954L5.11823 1.62374C5.35624 0.703186 6.13952 0.0917969 7.03595 0.0917969H11.1777C12.0643 0.0917969 12.8564 0.703186 13.0856 1.57518L13.2495 2.29855C13.3822 2.88813 13.8992 3.30531 14.4942 3.30531H17.4185ZM15.9329 17.0702C16.2382 14.259 16.7727 7.58027 16.7727 7.51289C16.7922 7.30877 16.7249 7.11554 16.5913 6.95997C16.4479 6.8143 16.2665 6.7281 16.0665 6.7281H2.15474C1.9538 6.7281 1.76262 6.8143 1.62996 6.95997C1.49535 7.11554 1.42902 7.30877 1.43877 7.51289C1.44056 7.52527 1.45974 7.76053 1.49181 8.15384C1.63426 9.90107 2.03102 14.7674 2.2874 17.0702C2.46884 18.7666 3.59547 19.8328 5.22738 19.8715C6.48668 19.9002 7.78402 19.9101 9.11062 19.9101C10.3602 19.9101 11.6292 19.9002 12.9275 19.8715C14.616 19.8427 15.7417 18.7953 15.9329 17.0702Z"
                                  fill="#D4D8E2" />
                              </svg>
                            </button>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="" style="text-align: center; margin-top: 2em">
                      <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change="handleCurrentChange"
                        :page-size="pageSize"
                        :total="total">
                      </el-pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- MODAL CONTENT -->
        <!-- DELETE, UPDATE AND VIEW CONTACT MODAL  START -->
        <!-- DELETE,UPDATE AND VIEW CONTACT MODAL END -->
        <messageModal
          @saveTemplate="saveTemplate"
          @editTemplate="editTemplate"
          @deleteSuccess="handleDeleteSucccess"
          @needsRefreshOnScheduled="needsRefresh"
          :payload="dataPayload"
          :from="from"
          ref="messageModal" />
      </div>
    </div>
  </div>
</template>

<script>
  import $ from "jquery";
  import messageModal from "./messageModal.vue";
  import store from "@/state/store.js";
  export default {
    name: "messages",
    components: { messageModal },
    data() {
      return {
        statusColorStyles: {
          color: "springgreen",
        },
        currentStatus: "PENDING",
        page: 1,
        pageSize: 20,
        total: 0,
        messages: "",
        success: "",
        errors: "",
        modalType: "",
        isDelete: false,
        messageTemplates: [],
        rules: {},
        loadingTemplate: false,
        formData: {
          sederId: "",
          campaigne: "",
          content: "",
        },
        sendMessage: {},
        sendMessageRules: {},
        from: "",
        dataPayload: {
          currentModal: "",
          selectedKey: "",
          selectedMessage: {},
        },
        loadingTable: false,
      };
    },
    computed: {
      searching() {
        if (!this.search) {
          this.total = this.messageTemplatesComputed.length;
          return this.messageTemplates;
        }
        this.page = 1;
        return this.messageTemplatesComputed.filter(
          (data) =>
            data.title.toLowerCase().includes(this.search.toLowerCase()) ||
            data.voice_note_title
              .toLowerCase()
              .includes(this.search.toLowerCase())
        );
      },
      displayData() {
        this.total = this.searching.length;
        return this.searching.slice(
          this.pageSize * this.page - this.pageSize,
          this.pageSize * this.page
        );
      },
      messageTemplatesComputed() {
        return this.messageTemplates;
      },
      customWidth() {
        if (screen.width < 768) {
          return "145px";
        } else {
          return "max-content";
        }
      },
      customHeight() {
        if (screen.width < 768) {
          return "50vh";
        } else {
          return "60vh";
        }
      },
    },
    methods: {
      handleCurrentChange(val) {
        this.page = val;
      },
      saveTemplate(payload) {
        this.messageTemplates.push(payload);
      },
      editTemplate(payload) {
        this.loadingTable = true;
        this.messageTemplates[payload.key] = payload.data;
        this.messageTemplates = [...this.messageTemplates];
        this.loadingTable = false;
      },
      handleDeleteSucccess(id) {
        this.loadingTable = true;
        this.messageTemplates.splice(id, 1);
        this.loadingTable = false;
      },
      opendModal({ message, key, type, deleteType, sendType }) {
        if (type === "delete") {
          if (deleteType && deleteType == "__scheduled") {
            this.dataPayload = {
              currentModal: "delete",
              selectedKey: key,
              selectedMessage: message,
              deleteType: "__scheduled",
              deleteMessage: this.$t(
                "dashboard.send_message.scheduled_messages.delete"
              ),
            };
          } else {
            this.dataPayload = {
              currentModal: "delete",
              selectedKey: key,
              selectedMessage: message,
            };
          }
          store.dispatch("uistate/addContactFormData", {
            title: "",
            type: "delete",
          });
        }
        if (type === "send") {
          let payload = {
            id: message.campaign_id,
            body: message.message,
            title: message.campaign_name,
            date_time: message.date_time,
            is_repeated: message.is_repeated,
            repeated_period: message.repeated_period,
          };
          if (sendType && sendType == "__scheduled") {
            this.dataPayload = {
              currentModal: "send",
              selectedKey: key,
              selectedMessage: payload,
              messageType: "__scheduled",
            };
            store.dispatch("modal/setFromSchedule", true);
          } else {
            this.dataPayload = {
              currentModal: "send",
              selectedKey: key,
              selectedMessage: message,
            };
          }
          store.dispatch("uistate/addContactFormData", {
            title: "",
            type: "view",
          });
          store.dispatch("modal/addFormData", {
            currentPage: "view",
            nextPage: "",
            prevPage: "",
            formData: {
              campaign_id: message.campaign_id,
              message: message.message,
              campaign_name: message.campaign_name,
              date_time: message.date_time,
              isEdit: true,
              sender_id: message.sender_id,
              pageTitle: "",
            },
          });
        }
        return this.showModal();
      },
      getScheduledMessages() {
        this.loadingTable = true;
        store
          .dispatch("campaigns/fetchScheduledMessages")
          .then((res) => {
            // this.loadingTable = false
            this.messageTemplates = [...res.data.data];
          })
          .catch((err) => {
            // this.loadingTable = false
          })
          .finally(() => {
            this.loadingTable = false;
          });
      },
      showModal() {
        let element = this.$refs.messageModal.$el;
        $(element).modal("show");
      },
      openMessageModal(type) {
        if (type === "sendMessage") {
          this.from = "simpleMessage";
          this.$emit("SMSType", "simpleMessage");
          store.dispatch("uistate/addContactFormData", {
            title: this.$t(
              "dashboard.send_message.message_templates.send_message"
            ),
            type: "selectSenderId",
            fromMessage: true,
            isScheduled: true,
          });
          store.dispatch("uistate/setFromScheduled", true);
        } else if (type === "template") {
          this.$emit("SMSType", "template");
          this.from = "template";
          store.dispatch("uistate/addContactFormData", {
            title: this.$t(
              "dashboard.send_message.message_templates.create_template"
            ),
            type: "template",
            fromTemplate: true,
          });
          //  store.dispatch('uistate/addContactFormData', {
          //     title: "Send Message",
          //     type: 'details',
          //     fromTemplate: true
          // })
        }
        return this.showModal();
      },
      needsRefresh() {
        // this.loadingTemplate = true
        this.loadingTable = true;
        this.getScheduledMessages();
        // this.loadingTemplate = false
        this.loadingTable = false;
      },
    },
    mounted() {
      this.loadingTemplate = true;
      store
        .dispatch("campaigns/fetchScheduledMessages")
        .then((res) => {
          // this.loadingTable = false
          this.messageTemplates = [...res.data.data];
        })
        .catch((err) => {
          // this.loadingTable = false
        })
        .finally(() => {
          this.loadingTemplate = false;
        });
      // Analytics
      this.$mixpanel.track("Schduled SMS Messages Page");
      // for franco
      if (this.$store.getters["auth/currentUser"].country === "CI") {
        this.$mixpanelFranco.track("Schduled SMS Messages Page");
      }
    },
  };
</script>
<style scoped lang="scss">
  @import url("../../../styles/messages/___index.scss");

  .__create_a_new_template {
    outline: none;
    border: none;
    background: transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: #484a4f;
    margin-top: 1em;
  }

  ._text_content {
    max-width: 300px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #575761;
  }

  .is-active {
    color: #f7921c;
    border: 1px solid #f7921c;
  }

  .__send:hover svg path {
    fill: #f7921c;
    transition: all 0.3s ease;
  }

  ._delete:hover svg path {
    fill: red;
    transition: all 0.3s ease;
  }

  .create-template {
    border-radius: 25px;
    border-color: #f7921c;
    background-color: #f7921c;
    color: white;
    width: auto;
    padding-inline: 1rem;
  }

  .contains-message-buttons {
    display: flex;
    // flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
  }

  .actions-icons {
    display: flex;
    justify-content: flex-end;
    width: 50%;
  }

  .scrollable {
    overflow-y: hidden;
    height: 88vh;
    overflow-x: hidden;
    /* padding-bottom: calc(20vh - 100px); */
  }

  @media screen and (max-width: 450px) {
    .scrollable {
      overflow-y: auto;
    }

    .main-buttons-container * {
      display: block;
    }

    .create-template {
      width: 90%;
      font-size: small;
    }

    .main-buttons-container {
      width: 100% !important;
    }

    .contains-message-buttons {
      width: 100% !important;
    }

    .contains-message-buttons button {
      width: 90% !important;
    }
  }

  .international-button {
    width: fit-content;
  }

  .is-new {
    background-color: #f56c6c;
    color: white;
    border: none;
    padding: 2px;
    border-radius: 5px;
    animation: new 1s ease;
    display: inline-block;
  }

  @keyframes new {
    30% {
      transform: scale(1.2);
    }

    40%,
    60% {
      transform: rotate(-20deg) scale(1.2);
    }

    50% {
      transform: rotate(20deg) scale(1.2);
    }

    70% {
      transform: rotate(0deg) scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }
</style>
